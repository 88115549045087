exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-pages-blog-hobby-stuff-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/nolanpestano/Documents/GitHub/nolan-portfolio/src/pages/blog/hobby-stuff/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-pages-blog-hobby-stuff-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-pages-blog-other-stuff-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/nolanpestano/Documents/GitHub/nolan-portfolio/src/pages/blog/other-stuff/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-pages-blog-other-stuff-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-pages-blog-redux-society-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/nolanpestano/Documents/GitHub/nolan-portfolio/src/pages/blog/redux-society/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-pages-blog-redux-society-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-pages-blog-ugafinance-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/nolanpestano/Documents/GitHub/nolan-portfolio/src/pages/blog/ugafinance/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-pages-blog-ugafinance-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

